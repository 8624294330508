<template>
  <el-card v-loading="tableLoading" class="box-card">
    <div class="title">
      <!-- {{ translateTitle("账户") }} -->
      <Header />
    </div>
    <!-- <Headers /> -->
    <div class="setting-detail">
      <topHeader />
    </div>
    
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mt30">
      <el-breadcrumb-item :to="{ path: '/kyc' }">KYC</el-breadcrumb-item>
      <el-breadcrumb-item>{{translateTitle('KYC详情')}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-table  :data="tableData" style="width: 100%" class="mt30">
      <el-table-column
        prop="name"
        min-width="100"
        :label="translateTitle('文件类型')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <p class="p1">
            {{ scope.row.fileType == "1" ? translateTitle('身份证明') : translateTitle('地址证明') }}
          </p>
          <p class="p2">
            {{ scope.row.fileType == "1" ? translateTitle('证件号码') : translateTitle('国家') }}
          </p>
          <p class="p1">{{ scope.row.idNumber }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        min-width="100"
        :label="translateTitle('状态')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
           <!--  -->
          <span v-if="scope.row.uploadStylopayStatus =='0'" class="succ">{{ translateTitle('审核中') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='1'" class="succ">{{ translateTitle('审核中') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='2'" class="err">{{ translateTitle('银行已拒绝') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='3'" class="succ"> {{ translateTitle('银行审核通过') }}</span>
          <!-- <span :class="scope.row.approvalType == '1' ? 'succ' : 'err'">{{
            scope.row.approvalType == "0"
              ? translateTitle('待审核')
              : scope.row.approvalType == "1"
              ? translateTitle('通过')
              :  translateTitle('不通过')
          }}</span> -->
          <p class="p2">{{ scope.row.fileType == "1" ? translateTitle('证件类型') : "" }}</p>
          <!-- 地区 -->
          <p class="p1">{{ scope.row.idType }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        min-width="100"
        :label="translateTitle('行为')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="fw400 base-color" @click="showdialog(scope.row)"
            >{{translateTitle('查看文件')}}</div
          >
          <p class="p2">{{ scope.row.fileType == "1" ? translateTitle('过期时间') : "" }}</p>
          <!-- 城市 -->
          <p class="p1">{{ scope.row.idExpire }}</p>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        min-width="100"
        :label="translateTitle('文件名称')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <p class="p1">{{ scope.row.fileName }}</p>
          <p class="p2">
            {{ scope.row.fileType == "1" ? translateTitle('证件发行国家') : "" }}
          </p>
          <!-- 详细地址 -->
          <p class="p1">{{ scope.row.nationality }}</p>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="reason"
        min-width="100"
        :label="translateTitle('银行状态')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.uploadStylopayStatus =='0'" class="err">{{ translateTitle('未上传') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='1'" class="succ">{{ translateTitle('审核中') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='2'" class="err">{{ translateTitle('银行已拒绝') }}</span>
          <span v-else-if="scope.row.uploadStylopayStatus =='3'" class="succ"> {{ translateTitle('银行审核通过') }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        prop="reason"
        min-width="100"
        :label="translateTitle('原因')"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span :class="false ? 'succ' : 'err'">{{ scope.row.reason }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="address" :label="translateTitle('操作')" width="200">
        <template v-slot="scope">
          <div class="disal">
            <el-button
              v-if="scope.row.approvalType != '1'"
              size="medium"
              type="text"
              class="common-color"
              @click="handleClickBtn('edit', 1, scope.row)"
              >{{translateTitle('编辑')}}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <Pagination
      :page.sync="page"
      :limit.sync="pageSize"
      :total="total"
      @pagination="getTableData"
    />
    <!-- 新增编辑kyc -->
    <DialogPop
      width="600px"
      :title="bindingTitle"
      :visible="Visible"
       :sureText="translateTitle('确定')"
       :closeText="translateTitle('取消')"
      :btnloading="btnloading"
      @onClose="onClose"
      @onSure="onSure('ruleForm')"
    >
      <div class="change-form">
        <el-form
          label-position="top"
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          hide-required-asterisk
        >
          <el-form-item v-if="kycType == '1'" :label="translateTitle('国籍')" prop="nationality">
            <el-select
              v-model="ruleForm.nationality"
              filterable
              clearable
              :placeholder="translateTitle('输入国籍')"
            >
              <el-option
                v-for="item in options"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('证件类型')" prop="idType">
            <el-select v-model="ruleForm.idType" :placeholder="translateTitle('请选择')">
              <el-option
                v-for="item in options1"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('证件号码')" prop="idNumber">
            <el-input
              v-model="ruleForm.idNumber"
              :placeholder="translateTitle('输入证件号码')"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="kycType == '1'" :label="translateTitle('过期时间')" prop="idExpire">
            <el-date-picker
              v-model="ruleForm.idExpire"
              clearable
              format="yyyy-MM-dd"
              :placeholder="translateTitle('选择日期')"
              type="date"
              value-format="yyyy-MM-dd"
            />
          </el-form-item>
          <el-form-item :label="translateTitle('文件上传')" prop="filePath">
            <el-upload
              list-type="picture-card"
              :action= "fileUrl+ `/sys/file/upload`"
              :before-upload="handleBeforeUpload"
              class="avatar-uploader"
              :headers="uploadHeader"
              :on-error="handleError"
              :on-success="handleUploadSuccess"
              :show-file-list="false"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
            >
              <el-image
                style="width: 100%; height: 100%"
                v-if="ruleForm.filePath"
                :src="fileUrl + ruleForm.filePath"
                fit="fill"
              ></el-image>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">{{ translateTitle('只能上传文件') }}</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </DialogPop>
    <DialogPop width="600px"
      title=""
      @onClose="onClose1"
      :visible="dialogVisible"
      :closeBtn="false"
      :sureBtn="false"
    >
      <img
        width="100%"
        :src="fileUrl + checkRow.filePath"
        alt=""
      />
    </DialogPop>
  </el-card>
</template>

<script>
import { getToken } from "@/utils/token";
import Headers from "../../components/accountHeader/index.vue";
import topHeader from "../../components/topHeader/index"
import { addKyc, KYCPage } from "@/api/kyc";
import { getNational } from "@/api/accountManage";
import axios from "axios";
import { translateTitle } from "@/utils/i18n";
export default {
  components: { Headers, topHeader },
  filters: {
      statusFilter(status) {
        const statusMap = {
          0: '未上传',
          1: '审核中',
          2: '银行已拒绝',
          3: '银行审核通过',
        }
        return statusMap[status]
      }
    },
  data() {
    return {
      value1: false,
      total: 20,
      size: 10,
      tableData: [],
      tableLoading: false,
      nationalitylogo: '',
      options1: ["passport", "IdentityCard"],
      uploadHeader: {
        token: getToken(),
      },
      rules: {
        filePath: [
          { required: true, message: this.translateTitle("请上传图片"), trigger: "change" },
        ],
        nationality: [
          { required: true, message: this.translateTitle("请输入国籍"), trigger: "change" },
        ],
        gender: [{ required: true, message: this.translateTitle("请选择性别"), trigger: "change" }],
        idNumber: [
          { required: true, message: this.translateTitle("请输入证件号码"), trigger: "change" },
        ],
        idType: [
          { required: true, message: this.translateTitle("请选择证件类型"), trigger: "change" },
        ],
        idExpire: [
          { required: true, message: this.translateTitle("请选择过期时间"), trigger: "change" },
        ],
      },
      bindingTitle: this.translateTitle("新增KYC"),
      ruleForm: {
        fileName: "",
        filePath: "",
        id: "",
        idExpire: "",
        idNumber: "",
        idType: "",
        nationality: "",
        fileType: "",
      },
      activeName: "first",
      pageSize: 10,
      page: 1,
      Visible: false,
      options: [],
      dialogVisible: false,
      btnloading: false,
      checkRow: {},
      kycType: "",
      fileUrl: process.env.NODE_ENV == 'production' ? 'https://api.bankeracard.com/client_side' : 'http://13.214.25.79:9001/client_side',
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    translateTitle,
    //获取分页数据
    async getTableData() {
      const data = {
        pageSize: this.pageSize,
        page: this.page,
        baseUserId: 1,
      };
      const loading = this.$loading({
              lock: true,
              text: '',
              spinner: '',
              background: 'rgba(255, 255, 255, 0.8)'
            });
      // this.tableLoading= true
      let res = await KYCPage(data);
      if (res.code == 200) {
        this.tableData = res.data.records;
        this.total = res.data.total;
        // this.tableLoading= false
        loading.close()
      } else {
        this.$message.error(this.translateTitle(res.msg));
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleBeforeUpload(file) {
        // const isJPG = file.type === 'image/jpeg';
        const isJPG =
          file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error(this.translateTitle('格式错误'));
        }
        if (!isLt2M) {
          this.$message.error(this.translateTitle("请选择小于2M的文件"));
        }
        this.loading = true;
        return isJPG && isLt2M;
      },
    handleError() {
      this.loading = false;
      this.$message.error(this.translateTitle("上传失败"));
    },
    handleUploadSuccess(res) {
      this.loading = false;
      if (res.code !== 200) {
        this.$message.warning(res.msg);
        return;
      }
      this.$message.success(this.translateTitle("上传成功"));
      this.ruleForm.filePath = res.data.filePath;
      this.ruleForm.fileName = res.data.fileName;
      // Object.assign(this.uploadRow, res.data)
    },
    handlePictureCardPreview(file) {
      this.dialogVisible = true;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    showdialog(row) {
      this.dialogVisible = true;
      this.checkRow = row;
    },
    onClose() {
      this.$refs["ruleForm"].resetFields();
      this.Visible = false;
    },
    onClose1() {
      this.dialogVisible = false
    },
    onSure(ruleForm) {
      let params;
      let _request;
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.btnloading = true
          addKyc(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(this.translateTitle("操作成功"));
              this.getTableData();
              this.Visible = false;
              this.$refs["ruleForm"].resetFields();
            } else {
               this.$message.error(this.translateTitle(res.msg));
            }
          }).finally(() => {
            this.btnloading = false
          });
          // alert("submit!");
        } else {
          return false;
        }
      });
      this.visible = true;
    },
    handleClickBtn(type, type1, val) {
      val.fileType == "2" ? 
      this.$router.push({
                path: "/kycAddress",
                query: {
                  id: val.id,
                },
              }):
        this.$router.push({
                path: "/kycIdentity",
                query: {
                  id: val.id,
                },
              });
      // this.Visible = true;

      // this.getNationalData(); 
      // if (type == "edit") {
      //   this.kycType = val.fileType;
      //   this.ruleForm.fileType = val.fileType;
      //   this.ruleForm = val;

      //   this.bindingTitle = val.fileType == "2" ? this.translateTitle("编辑KYC地址") : this.translateTitle("编辑KYC身份");
      // } else {
      //   this.kycType = type1;
      //   this.ruleForm.fileType = type1;
      //   this.bindingTitle = type1 == "2" ? this.translateTitle("编辑KYC地址") : this.translateTitle("编辑KYC身份");
      // }
    },
    async getNationalData() {
      let res = await getNational();
      this.options = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 26px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-bottom: 20px;
  text-align: right;
}
.setting-detail {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: bold;
  color: #31363e;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
}
.succ {
  color: #3ad3b9;
}
.err {
  color: #d33a3a;
}
::v-deep {
  .el-table__cell {
    font-size: 14px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    .p1 {
      color: #31363e;
    }
    .p2 {
      color: #b9bfca;
      margin: 5px 0px;
    }
  }
  .el-tabs__nav-wrap::after {
    background-color: transparent;
    height: 0;
  }
  .el-tabs__active-bar {
    background-color: #165BA4;
    height: 3px;
  }
  .el-tabs__item {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #b9bfca;
  }
  .el-tabs__item.is-active {
    font-size: 22px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: bold;
    color: #353a42;
  }
  .btn-list {
    flex: 1;
    text-align: right;
    .el-button--medium {
      width: 26%;
      height: 66px;
      background: #f8fafb;
      border: 1px solid #e2eaee;
      border-radius: 17px;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #353a42;
    }
  }
}
.change-form {
  background: #f2f7f9;
  padding: 30px;
  border-radius: 10px;

  ::v-deep {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-select {
      width: 100%;
    }
    .el-input__inner {
      height: 50px !important;
      line-height: 50px !important;
      border: none !important;
    }
    .el-form-item__label {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: bold;
      color: #b9bfca;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #06a43e !important;
      border-color: #06a43e !important;
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }
    .el-checkbox__inner:hover {
      border-color: #000;
    }
    //      .el-checkbox__inner {
    //     background-color: #06A43E !important;
    //     border-color: #06A43E !important;
    // }
  }
}
</style>
